import axios from 'axios';

export const pad = i => i.toString().padStart(2, '0');

export const chunk = (arr, size) =>
  arr.reduce((acc, val, idx) => {
    const chunkIndex = Math.floor(idx / size);

    if (!acc[chunkIndex]) {
      acc[chunkIndex] = []; // start a new chunk
    }

    acc[chunkIndex].push(val);

    return acc;
  }, []);

export const getTime = async () => {
  const res = await axios.get('/api/v1/time');

  return res.data.timestamp;
};

export const timeDiff = (start, end) => {
  if (start === null)
    return {
      formatted: '00:00:00',
    };
  const timestamp = Math.floor((end - start) / 1000);

  const hours = Math.floor(timestamp / 60 / 60);
  const minutes = Math.floor(timestamp / 60) - hours * 60;
  const seconds = Math.floor(timestamp % 60);

  return {
    hours,
    minutes,
    seconds,
    formatted: `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`,
    formattedMin: `${pad(minutes)}:${pad(seconds)}`,
  };
};
