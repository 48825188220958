import { makeAutoObservable, toJS } from 'mobx';
import axios from 'axios';

import hash from 'object-hash';
import stringify from 'json-stable-stringify';
import { getTime } from 'utils';

// Prevent recursive stringification
const filteredKeys = new Set([
  'rootStore',
  'lastReceivedHash',
  'updating',
  'plain',
  'stringified',
  'hash',
]);

class Store {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  // Internal values
  lastReceivedHash = '';
  updating = false;
  campaign = {};

  translations = {
    en: {
      charity: {
        title: 'Charity Info',
        name: 'Virtual Guardians Foundation',
        info: [
          {
            label: 'Mission',
            description:
              'The funds that you help raise at the end of the week are intended to support the Digital Street Workers (DSW) of the Virtual Guardians Foundation, an innovative program in the French-speaking world that helps young people online. Our DSWs offer confidential and personalized support, intervening directly where young people spend their time: on platforms like Twitch, Discord, and Kick while streamers are broadcasting.',
          },
        ],
      },
      sponsors: {
        title: 'Sponsor Info',
        sponsors: [
          {
            name: 'LevelUp Coffee',
            info: [
              "We would like to give a huge shoutout to Level Up Coffee for being a main sponsor of our event! Your support will fuel our players with the energy and focus they need to level up their game. We couldn't do it without your Prestige Level coffee by our side. Thank you for being an incredible teammate and helping us make this event a success!",
            ],
          },
          {
            name: 'Always Games',
            info: [
              "NoReset would like to thank Always Games, they're on site all weekend runing the Board games section. From Catan to Wingspan to Magic the Gathering, there will be something for everyone.",
            ],
          },
          {
            name: 'CIPC',
            info: [
              'Thank you CIPC for graciously providing us with gaming PCs that are available for attendees during the event!',
            ],
          },
          {
            name: 'Kingston Fury',
            info: [
              'Thank you Kingston Fury for being one of our PC Zone partners. Kingston is the leader in flash memory. From high-performance SSDs to powerful RAM, they are always there!',
            ],
          },
          {
            name: 'Loto-Québec',
            info: [
              "NoReset would like to thank one of it's main partners: La Zone de Jeux Indie Loto-Québec! The mini-tower is present in the main room surrounded by its legendary beanbags to allow you to discover new releases from our Quebec artisans.",
            ],
          },
          {
            name: 'Techni Productions',
            info: [
              'With their unrivaled technical skills and ultra-high quality professonal equipment. For years, Techni-productions has enabled us to produce events of superior quality.',
            ],
          },
        ],
      },
      raffles: {
        title: 'Raffle Info',
        data: [],
      },
    },
    fr: {
      charity: {
        title: 'Info fondation',
        name: 'Fondation des Gardiens Virtuels',
        info: [
          {
            label: 'Mission',
            description:
              'Les fonds que vous contribuez à ramasser en fin de semaine sont destinés à soutenir les Travailleurs de Rue Numériques (TRN) de la Fondation des Gardiens Virtuels, un programme innovant dans la francophonie qui vient en aide aux jeunes en ligne. Nos TRN offrent un soutien confidentiel et personnalisé, en intervenant directement là où les jeunes passent leur temps : sur des plateformes comme Twitch, Discord, et Kick pendant que les streamer.euse.s sont en diffusions. ',
          },
        ],
      },
      sponsors: {
        title: 'Sponsor Info',
        sponsors: [
          {
            name: 'LevelUp Coffee',
            info: [
              "Un grand merci à Level Up Coffee d'être un des partenaires principaux de notre événement!  Votre soutien donnera à nos joueurs l'énergie et la concentration nécessaires pour passer au niveau supérieur. Nous ne pourrions pas y arriver sans votre café Prestige à nos côtés. Merci d'être un coéquipier incroyable et de nous aider à faire de cet événement un succès!",
            ],
          },
          {
            name: 'Toujours Jeux',
            info: [
              'NoReset tient à remercier Toujours Jeux, ils sont sur place toute la fin de semaine pour animer la section Jeux de Table. De Catane à Wingspan en passant par Magic the Gathering, il y en aura pour tous les goûts.',
            ],
          },
          {
            name: 'CIPC',
            info: [
              'Merci à CIPC qui nous fournit gracieusement des PC de gaming qui sont à votre disposition lors de notre événement !',
            ],
          },
          {
            name: 'Kingston Fury',
            info: [
              "Merci Kingston Fury d'être un de nos partenaires de la Zone PC. Kingston est le leader en mémoire flash. De SSD ultra performants à de la mémoire vive surpuissante, ils sont toujours au rendez-vous!",
            ],
          },
          {
            name: 'Loto-Québec',
            info: [
              'NoReset tient à remercier un de nos partenaire principaux: La Zone de Jeux Indie Loto-Québec! La mini-tour est présente dans la salle principale entourée de ses légendaires bean bags pour vous permettre de découvrir des nouveautés de nos artisans québécois.',
            ],
          },
          {
            name: 'Techni Productions',
            info: [
              "Avec leurs compétences techniques inégalées et leur matériel professionnel d'ultra-haute qualité. Techni-productions nous permet depuis des années d'avoir une production d'événement d'une qualité supérieure.",
            ],
          },
        ],
      },
      raffles: {
        title: 'Raffle Info',
        data: [],
      },
    },
  };

  charityTimer = [null];
  sponsorTimers = [null, null, null, null, null, null];
  raffleTimers = [];

  onSite = 0.0;

  fundky = {
    id: 1912, // 1635 = NoReset5
    pollid: 103, // 89 - 90 - 91 = NoReset5
    frameid: 106, // 92 - 93 - 94 = NoReset5
    amount: 0.0,
    background: '',
    polls: [],
    milestones: [],
    donations: [],
    donationStatus: [],
    donationsHidden: [],
    donationsApproved: [],
    bidwarStatus: [],
    milestoneStatus: [],
    backgroundPoll: [],
    backgroundOptions: [],
    framePoll: [],
    frameOptions: [],
  };

  setDonations = amount => {
    this.onSite = amount;
  };

  getDonationTotal = () => {
    return this.fundky.amount + this.onSite;
  };

  setApproved = id => {
    if (!this.fundky.donationsApproved.find(e => e === id)) {
      this.fundky.donationsApproved.push(id);
      return;
    }
    this.fundky.donationsApproved = [...this.fundky.donationsApproved].filter(e => e !== id);
  };

  getApproved = id => {
    if (!this.fundky.donationsApproved.find(e => e === id)) {
      return false;
    }
    return true;
  };

  newRaffleInfo = () => {
    const { data } = this.translations.en.raffles;
    const { data: dataFR } = this.translations.fr.raffles;

    let newItem = {
      label: 'New Raffle Info',
      description: 'New Raffle Description',
      minAmount: '$0',
      timeFrame: 'Between now and when?',
      isOnsite: true,
      isComplete: false,
    };
    let newItemFR = {
      label: 'New Raffle Info',
      description: 'New Raffle Description',
      minAmount: '$0',
      timeFrame: 'Between now and when?',
      isOnsite: true,
      isComplete: false,
    };

    this.translations.en.raffles.data = [...data, newItem];
    this.translations.fr.raffles.data = [...dataFR, newItemFR];

    // Add a new null timer instance for the new charity info
    this.raffleTimers.push(null);
  };

  undoRaffleInfo = idx => {
    if (this.translations.en.raffles.data.length === 0) return;
    this.translations.en.raffles.data[idx].isComplete = false;
    this.translations.fr.raffles.data[idx].isComplete = false;
    this.raffleTimers[idx] = null;
  };

  removeRaffleInfo = idx => {
    if (this.translations.en.raffles.data.length === 0) return;
    this.translations.en.raffles.data[idx].isComplete = true;
    this.translations.fr.raffles.data[idx].isComplete = true;
    this.raffleTimers[idx] = null;
  };

  updateRaffleInfo = (key, val, lang, idx) => {
    this.translations[lang].raffles.data[idx][key] = val;
  };

  newCharityInfo = () => {
    const { info } = this.translations.en.charity;
    const { info: infoFR } = this.translations.fr.charity;
    let newItem = { label: 'New Charity Label', description: 'New Charity Info' };
    let newItemFR = { label: 'New Charity Label FR', description: 'New Charity Info FR' };

    this.translations.en.charity.info = [...info, newItem];
    this.translations.fr.charity.info = [...infoFR, newItemFR];

    // Add a new null timer instance for the new charity info
    this.charityTimer.push(null);
  };

  removeCharityInfo = idx => {
    if (this.translations.en.charity.info.length === 0) return;

    const { info } = this.translations.en.charity;
    const { info: infoFR } = this.translations.fr.charity;

    this.translations.en.charity.info = info.filter((_, index) => index !== idx);
    this.translations.fr.charity.info = infoFR.filter((_, index) => index !== idx);

    // Remove the corresponding timer
    this.charityTimer = this.charityTimer.filter((_, index) => index !== idx);
  };

  newSponsor = () => {
    const { sponsors } = this.translations.en.sponsors;
    const { sponsors: sponsorsFR } = this.translations.fr.sponsors;
    let newItem = { name: 'New Sponsor', info: ['New Sponsor Info'] };
    let newItemFR = { name: 'New Sponsor FR', info: ['New Sponsor Info FR'] };

    this.translations.en.sponsors.sponsors = [...sponsors, newItem];
    this.translations.fr.sponsors.sponsors = [...sponsorsFR, newItemFR];

    // Add a new null timer instance for the new sponsor
    this.sponsorTimers.push(null);
  };

  removeSponsor = idx => {
    if (this.translations.en.sponsors.sponsors.length === 0) return;

    const { sponsors } = this.translations.en.sponsors;
    const { sponsors: sponsorsFR } = this.translations.fr.sponsors;

    this.translations.en.sponsors.sponsors = sponsors.filter((_, index) => index !== idx);
    this.translations.fr.sponsors.sponsors = sponsorsFR.filter((_, index) => index !== idx);

    // Remove the corresponding timer
    this.sponsorTimers = this.sponsorTimers.filter((_, index) => index !== idx);
  };

  removeSponsorInfo = (idx, idx2) => {
    if (this.translations.en.sponsors.sponsors[idx].info.length === 0) return;
    const { info } = this.translations.en.sponsors.sponsors[idx];
    const { info: infoFR } = this.translations.fr.sponsors.sponsors[idx];
    this.translations.en.sponsors.sponsors[idx].info = [...info].filter(
      (element, index) => index !== idx2,
    );
    this.translations.fr.sponsors.sponsors[idx].info = [...infoFR].filter(
      (element, index) => index !== idx2,
    );
  };

  newSponsorInfo = idx => {
    const { info } = this.translations.en.sponsors.sponsors[idx];
    const { info: infoFR } = this.translations.fr.sponsors.sponsors[idx];
    this.translations.en.sponsors.sponsors[idx].info = [...info, `New Sponsor Info`];
    this.translations.fr.sponsors.sponsors[idx].info = [...infoFR, `New Sponsor Info`];
  };

  updateCharity = (key, val, lang, idx) => {
    if (key === 'name') this.translations[lang].charity.name = val;
    if (key === 'info') this.translations[lang].charity.info[idx].description = val;
    if (key === 'infoLabels') this.translations[lang].charity.info[idx].label = val;
  };

  updateSponsor = (key, val, lang, idx, idx2) => {
    if (key === 'name') this.translations[lang].sponsors.sponsors[idx].name = val;
    if (key === 'info') this.translations[lang].sponsors.sponsors[idx].info[idx2] = val;
  };

  getStatus = id => {
    if (!this.fundky.donationStatus.find(e => e === id)) {
      return false;
    }
    return true;
  };

  setStatus = id => {
    if (!this.fundky.donationStatus.find(e => e === id)) {
      this.fundky.donationStatus.push(id);
      return;
    }
    this.fundky.donationStatus = [...this.fundky.donationStatus].filter(e => e !== id);
  };

  getHidden = id => {
    if (!this.fundky.donationsHidden.find(e => e === id)) {
      return false;
    }
    return true;
  };

  setHidden = id => {
    if (!this.fundky.donationsHidden.find(e => e === id)) {
      this.fundky.donationsHidden.push(id);
      return;
    }
    this.fundky.donationsHidden = [...this.fundky.donationsHidden].filter(e => e !== id);
  };

  setFundkyID = id => (this.fundky.id = id);

  setBackgroundID = id => (this.fundky.pollid = id);

  setFrameID = id => (this.fundky.frameid = id);

  //fundky LIVE API url : https://fundky.live/en/api
  //fundky DEV API url : https://live.dev.fundky.com/en/api

  getPolls = async () => {
    try {
      let response = await axios.get(`/api/v1/polls/${this.fundky.id}`);
      if (response.status == 200)
        this.fundky.polls = await response.data.polls.filter(p => p.statusId);
    } catch (error) {
      this.fundky.polls = [];
      console.error("ERROR COULD'T FETCH POLLS", error.message);
    }
  };

  getAdmin = async id => {
    try {
      let { data } = await axios.get(`/api/v1/host/${id}`);
      return data.data;
    } catch (error) {
      return false;
    }
  };

  getTotalRaised = async () => {
    try {
      let response = await axios.get(`/api/v1/donations/total/${this.fundky.id}`);
      if (response.status == 200) this.fundky.amount = response.data.current;
    } catch (error) {
      this.fundky.amount = 0.0;
      console.error("ERROR COULD'T FETCH TOTAL RAISED --- ", error.message);
    }
  };

  getMilestones = async () => {
    try {
      let response = await axios.get(`/api/v1/milestones/${this.fundky.id}`);
      if (response.status == 200) this.fundky.milestones = response.data.milestones; //.filter(m => m.amount > this.fundky.amount);
    } catch (error) {
      this.fundky.milestones = [];
      console.error("ERROR COULD'T FETCH MILESTONES --- ", error.message);
    }
  };

  getDonationList = async () => {
    try {
      let response = await axios.get(`/api/v1/donations/list/${this.fundky.id}`);
      if (response.status === 200) this.fundky.donations = response.data.donations;
    } catch (error) {
      this.fundky.donations = [];
      console.log("ERROR COULD'T FETCH DONATION LIST", error.message);
    }
  };

  startTimer = (timerArray, index) => {
    if (timerArray === 0) {
      this.charityTimer[index] = Date.now();
      return;
    }
    if (timerArray === 1) {
      this.sponsorTimers[index] = Date.now();
      return;
    }
    this.raffleTimers[index] = Date.now();
  };

  stopTimer = (timerArray, index) => {
    if (timerArray === 0) {
      this.charityTimer[index] = null;
      return;
    }
    if (timerArray === 1) {
      this.sponsorTimers[index] = null;
      return;
    }
    this.raffleTimers[index] = null;
  };

  resetTimer = (timerArray, index) => {
    if (timerArray === 0) {
      this.charityTimer[index] = Date.now();
      return;
    }
    if (timerArray === 1) {
      this.sponsorTimers[index] = Date.now();
      return;
    }
    this.raffleTimers[index] = Date.now();
  };

  get jsonString() {
    return JSON.stringify(toJS(this));
  }

  get plain() {
    return toJS(this);
  }

  get stringified() {
    return stringify(this.plain, {
      replacer: (k, v) => {
        if (filteredKeys.has(k)) {
          // Remove it from the output
          return undefined;
        }

        return v;
      },
    });
  }

  get hash() {
    return hash(this.stringified);
  }
}

export default Store;
